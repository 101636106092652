.appStoreCardContainer {
  display: flex;
  flex-direction: column;
  min-height: 320px;
  padding: 20px;
  background-color: var(--colors-white);
  border-radius: 8px;
  border: 2px solid var(--colors-gray-70);
}

.appStoreCardContainer:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cardDetails {
  flex: 1;
  position: relative;
}

.cardLogoWrapper {
  margin-bottom: var(--sizes-4);
  display: flex;
  gap: var(--sizes-2);
  margin-top: -8px;
}

.logoContainer {
  margin-bottom: 24px;
}

.cardCta {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.image {
  margin-top: var(--sizes-2);
  max-height: 48px;
}

.tileName {
  line-height: 24px;
  font-weight: 600;
}

.tileDescription {
  color: var(--colors-gray-600);
  margin: 8px 0 24px 0;
  font-weight: 400;
  line-height: 24px;
}

.popup {
  color: var(--colors-primary-400);
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.typeformWidget {
  width: auto;
  height: 480px;
  border-radius: 0;
  overflow: hidden;
}
